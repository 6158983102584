.banner {
  margin-top: 0;
  padding: 150px 0 100px 0;
  background-image: url("../../imgs/banner-bg.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
 
.banner h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 1;
  margin: 20px auto;
  display: block;
}

@media screen and (max-width: 960px) {
  .banner h1 {
    font-size: 40px;
  }
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  transform: scale(1.3);
}

.banner img {
  margin-top: -20px;
  animation: updown 3s linear infinite;
}

.hero-title {
  margin-top: 100px;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}