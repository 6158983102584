.projects {
  padding: 50px;
  min-height: 100vh;
  position: relative;
  background-color: #030102;
  }

.projects h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.projects p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 70%;
}

.projects-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin: 20px 10px;
  width: 300px;
  height: 225px;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #C24817 -5.91%, #491225 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 80%;
  color: white;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

@media screen and (max-width:768px) {
  .proj-imgbx {
    border-radius: 30px;
    padding-bottom: 60px;
    overflow: visible;
    margin: 50px 10px;
    width: 300px;
    height: 225px;
  }

  .proj-imgbx::before {
    opacity: 0;
    height: 0;
  }

  .proj-imgbx:hover::before {
    height: 0;
  }

  .proj-cover {
    border-radius: 30px;
  }
  
  .proj-txtx {
    text-align: center;
    width: 80%;
    color: white;
    opacity: 1;
    position: absolute;
    top: 110%;
  }
  
  .proj-txtx h4 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
  }

  .proj-txtx span {
    font-size: 14px;
    letter-spacing: 0.8px;
  }
}