.contact {
  background: linear-gradient(90.21deg, #491225 -5.91%, #030102 111.58%);
  padding: 30px 0 50px 0;
}

.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input, .contact form textarea {
  width: 100%;
  background: #030102;
  border: 2px solid #030102;
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus, .contact form textarea:focus {
  background: white;
  color: #030102;
}

.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #030102;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #030102;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 20px;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #B52E05;
  width: 0;
  height: 100%;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: -2px;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 104%;
}

@media screen and (max-width:768px) {
  .contact {
    padding: 30px;
  }

  .contact form input, .contact form textarea {
    width: 90%;
  }

  .contact form button {
    width: 90%;
  }
}